import * as React from 'react';
import { Text } from '@westhechiang/text';
import { Button as BaseButton } from '@westhechiang/button';

export const Button = ({
  children,
  textProps,
  ...props
}) => {
  return (
    <BaseButton
      width={[1, 240]}
      height={60}
      borderColor="mustard"
      borderRadius={0}
      border={1}
      boxShadow="1px 1px 1px rgba(10,10,10,.2)"
      bg="mustard"
      {...props}
    >
      <Text
        m={0}
        color="white"
        fontWeight={600}
        fontFamily="body"
        textTransform="uppercase"
        fontSize={4}
        {...textProps}
      >
        {children}
      </Text>
    </BaseButton>
  );
};
