import * as React from 'react';
import { withTheme } from 'styled-components';
import { bubble as Menu } from 'react-burger-menu';
import { GatsbyLink } from '../../../core/gatsbyLink';
import { NavigationContext, routes } from '../Navigation';

export const MobileSidebar = withTheme(({ theme }) => {
  const styles = {
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
      marginTop: 0,
      zIndex: 5,
    },
    bmMenu: {
      padding: '20px',
      fontSize: '1.15em',
    },
    bmMorphShape: {
      fill: 'rgba(255, 255, 255, 0.9)',
    },
    bmItemList: {
      padding: '10px',
      display: 'flex',
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    bmItem: {
      display: 'inline-block',
      marginBottom: 20,
      color: theme.colors.magenta,
      fontFamily: theme.fonts.body,
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
      marginTop: 0,
      zIndex: 4,
    },
  };

  const { isSidebarOpen, setIsSidebarOpen } = React.useContext(
    NavigationContext,
  );

  return (
    <Menu
      width={150}
      styles={styles}
      disableAutoFocus
      right
      isOpen={isSidebarOpen}
      customBurgerIcon={false}
      customCrossIcon={false}
      onStateChange={(state) => {
        setIsSidebarOpen(state.isOpen);
      }}
    >
      <GatsbyLink to="/">Home</GatsbyLink>
      {routes.map((route) => (
        <GatsbyLink key={route.label} to={route.path}>
          {route.label}
        </GatsbyLink>
      ))}
    </Menu>
  );
});
