import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { ModalProvider, Modal } from '../Modal';
import Container from '../Container';
import { Navigation, NavigationProvider } from './components/Navigation';
import Theme, { GlobalStyle } from '../core/theme';
import { Footer } from '../Footer';
import { MobileSidebar } from './components/MobileSidebar';

interface Props {
  children: any;
}

class Layout extends React.Component<Props> {
  public render() {
    const { children } = this.props;

    return (
      <ThemeProvider theme={Theme}>
        <ModalProvider>
          <NavigationProvider>
            <Container>
              <Modal />
              <Navigation />
              <MobileSidebar />
              <GlobalStyle />
              {children}
              <Footer />
            </Container>
          </NavigationProvider>
        </ModalProvider>
      </ThemeProvider>
    );
  }
}

export default Layout;
