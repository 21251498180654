import React, { Children } from 'react';
import { Text } from '@westhechiang/text';

export const Heading = ({ children, ...props }) => (
  <Text
    boxSizing="border-box"
    fontFamily="heading"
    fontSize={[8, 8, 10]}
    m={0}
    {...props}
  >
    {children}
  </Text>
);

export default Heading;
