import React from 'react';
import { Button } from '../Button/Button';
import { ModalContext, Modal } from '.';

export const ShowModalButton = ({
  children,
  modal,
  modalProps,
  ...props
}) => {
  const { showModal } = React.useContext(ModalContext);

  return (
    <Button
      onClick={() => showModal(modal, modalProps)}
      {...props}
    >
      {children}
    </Button>
  );
}

ShowModalButton.defaultProps = {
  modal: 'FORM_CONTACT_US',
  modalProps: {},
}