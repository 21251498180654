import * as React from 'react';
import styled from 'styled-components';
import {
  borderColor,
  BorderColorProps,
  borders,
  color,
  fontFamily,
  FontFamilyProps,
  fontSize,
  fontWeight,
  height,
  lineHeight,
  space,
  display,
  DisplayProps,
  textAlign,
  width,
  ColorProps,
  FontSizeProps,
  WidthProps,
  HeightProps,
  LineHeightProps,
  SpaceProps,
  FontWeightProps,
  TextAlignProps,
  BordersProps,
} from 'styled-system';

interface TextAreaProps
  extends ColorProps,
    FontSizeProps,
    WidthProps,
    DisplayProps,
    HeightProps,
    LineHeightProps,
    SpaceProps,
    FontWeightProps,
    TextAlignProps,
    BordersProps,
    BorderColorProps,
    FontFamilyProps {
  textIndent: string;
  placeholderColor: string;
}

export const TextArea = styled.textarea<TextAreaProps>`
  ${color}
  ${fontSize}
  ${fontFamily}
  ${width}
  ${height}
  ${lineHeight}
  ${space}
  ${fontWeight}
  ${textAlign}
  ${borders}
  ${borderColor}
  ${display}
  box-sizing: border-box;
  box-shadow: none;
  text-indent: 20px;

  &::placeholder {
    ${fontSize}
    color: ${(props) => props.placeholderColor || 'black'};
    ${fontFamily}

`;
