import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Flex } from '@westhechiang/flex';
import { BulletPoint } from '../../BulletPoint';
import {
  fontFamily,
  fontSize,
  width,
  display,
  height,
  maxWidth,
  minWidth,
  space,
  borders,
  borderColor,
  color,
  textAlign,
  lineHeight,
  fontWeight,
  letterSpacing,
} from 'styled-system';

export const StyledGatsbyLink = styled(
  ({ textTransform, children, ...rest }) => <Link {...rest}>{children}</Link>,
)`
  ${fontFamily}
  ${fontSize}
  ${width}
  ${height}
  ${maxWidth}
  ${minWidth}
  ${space}
  ${borders}
  ${color}
  ${textAlign}
  ${lineHeight}
  ${fontWeight}
  ${letterSpacing}
  ${borderColor}
  ${display}
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: ${(props) => props.textIndent};
  text-transform: ${(props) => props.textTransform || 'none'};
  font-style: ${(props) => props.fontStyle || 'normal'};
  white-space: ${(props) => props.whiteSpace || 'normal'};
  opacity: ${(props) => props.opacity || '1'};
  box-sizing: ${(props) => props.boxSizing || 'content-box'};
  text-decoration: ${(props) => props.textDecoration || 'none'};
  transition: ${(props) => props.transition || 'none'};

  &:hover {
    cursor: pointer;
  }
`;

export const GatsbyLink = ({ containerProps, isActive, ...props }) => {
  return (
    <Flex flexDirection="column" {...containerProps}>
      <StyledGatsbyLink color={isActive ? 'magenta' : 'darkGray'} {...props} />

      {isActive && (
        <Flex justifyContent="center">
          <BulletPoint mt="6px" color="magenta" mb="-10px" />
        </Flex>
      )}
    </Flex>
  );
};
