import * as React from 'react';
import { Box } from '@westhechiang/box';

interface ContainerProps {
  children: any;
}

const Container = ({ children }: ContainerProps) => (
  <Box width={1} position="relative">
    {children}
  </Box>
);

export default Container;
