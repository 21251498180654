import React from 'react';
import { Flex } from '@westhechiang/flex';

export const Page = ({ children, ...props }) => (
  <Flex
    alignItems="center"
    bg="pink"
    flexDirection="column"
    minHeight="100vh"
    overflowX="hidden"
    width={'100%'}
    {...props}
  >
    {children}
  </Flex>
);

export default Page;
