import React, { useState, createContext } from 'react';

export const NavigationContext = createContext({
  isSidebarOpen: false,
  closeSidebar: undefined,
  openSidebar: undefined,
});

export const NavigationProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <NavigationContext.Provider
      value={{
        closeSidebar,
        isSidebarOpen,
        openSidebar,
        setIsSidebarOpen,
        toggleSidebar,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
