export const routes = [
  {
    label: 'Services',
    path: '/#services',
  },
  {
    label: 'About Us',
    path: '/about-us',
  },
  {
    label: 'Cannabis',
    path: '/cannabis',
  },
];
