import * as React from 'react';
import { HamburgerButton } from 'react-hamburger-button';
import { withTheme } from 'styled-components';
import { Flex } from '@westhechiang/flex';
import { Box } from '@westhechiang/box';
import { Location } from '@reach/router';
import { NavigationContext } from '../Navigation';

import { GatsbyLink } from '../../../core/gatsbyLink';
import { BodyContainer } from '../../../BodyContainer';
import { routes } from './routes';

const BaseNavigation = () => {
  const [scrolledPercentage, setScrolledPercentage] = React.useState(0);
  const { isSidebarOpen, toggleSidebar } = React.useContext(NavigationContext);

  React.useEffect(() => {
    window.addEventListener('scroll', listenToScroll);

    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, []);

  const listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    setScrolledPercentage(winScroll / height);
  };

  return (
    <Flex
      width="100vw"
      height={[60, 90]}
      display={['flex']}
      bg={[
        'transparent',
        'rgba(255, 255, 255, 0.8)',
        scrolledPercentage > 0.01 ? 'rgba(255, 255, 255, 0.8)' : 'transparent',
      ]}
      transition="0.3s ease-in-out background-color"
      position={['fixed']}
      zIndex={5}
      top={0}
      left={0}
      justifyContent="center"
      alignItems="center"
    >
      <Flex
        width={1}
        height={[60, 90]}
        alignItems="center"
        justifyContent="center"
        position="relative"
        px={3}
      >
        <BodyContainer
          justifyContent={['space-between', 'space-between', 'flex-start']}
        >
          <Box display={['none', 'block']}>
            <GatsbyLink
              color="purple"
              fontFamily="logo"
              fontSize={10}
              fontWeight={500}
              m={0}
              to="/"
            >
              Doja
            </GatsbyLink>
          </Box>
          <Box display={['block', 'block', 'none']}>
            <HamburgerButton
              color="white"
              height={20}
              onClick={toggleSidebar}
              open={isSidebarOpen}
              strokeWidth={1}
              width={30}
            />
          </Box>
          <Flex ml={[50]} display={['none', 'none', 'flex']}>
            <Location>
              {({ location }) => (
                <>
                  {routes.map((route) => (
                    <GatsbyLink
                      fontFamily="body"
                      fontSize={2}
                      fontWeight="bold"
                      key={route.label}
                      isActive={location.pathname === route.path}
                      letterSpacing="1px"
                      containerProps={{
                        mr: 50,
                      }}
                      textTransform="uppercase"
                      to={route.path}
                    >
                      {route.label}
                    </GatsbyLink>
                  ))}
                </>
              )}
            </Location>
          </Flex>
        </BodyContainer>
      </Flex>
    </Flex>
  );
};

export const Navigation = withTheme(BaseNavigation);
