import React from 'react';
import { Heading } from '../Heading';

export const PinkHeading = ({ children, ...props }) => (
  <Heading color="magenta" {...props}>
    {children}
  </Heading>
);

export default PinkHeading;
