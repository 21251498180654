import React from 'react';
import { Heading } from '../Heading';

export const PurpleHeading = ({ children, ...props }) => (
  <Heading color="purple" {...props}>
    {children}
  </Heading>
);

export default PurpleHeading;
