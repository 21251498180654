import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

// global css; only add to this if there are no other options
export const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
`;

const colors = {
  darkGray: '#4A5969',
  black: '#000000',
  transparentWhite8: 'rgba(255, 255, 255, 0.8)',
  transparentWhite9: 'rgba(255, 255, 255, 0.9)',
  transparentBlack: 'rgba(0, 0, 0, 0.4)',
  lightGray: '#BCC8D6',
  white: '#FFFFFF',
  coral: '#E16255',
  pink: '#FEF2FC',
  magenta: '#ED63D2',
  purple: '#7551E9',
  orange: {
    500: '#d09b69',
  },
  mustard: '#F9B723',
  brightOrange: '#FF7D51',
  brightGreen: '#2FC872',
  blue: '#0BAFFF',
};

const fontSizes = [
  8, // 0
  10, // 1
  12, // 2
  16, // 3
  18, // 4
  20, // 5
  22, // 6
  24, // 7
  26, // 8
  32, // 9
  36, // 10
  40, // 11
  48, // 12
];

const breakpoints = [
  '40em', // 640px
  '52em', // 832px
  '64em', // 1024px
  '120em', // 1920px
];

const space = [
  0, // 0
  5, // 1
  10, // 2
  20, // 3
  40, // 4
  60, // 5
];

const fontWeights = [
  200, // 0
  300, // 1
  400, // 2
  500, // 3
  600, // 4
  700, // 5
  800, // 6
  900, // 7
];

const borders = [0, '1px solid', '2px solid'];

const letterSpacings = [];

const radii = [
  0, // 0
  2, // 1
  4, // 2
  8, // 3
];

const lineHeights = [1, 1.125, 1.25, 1.5, 1.7, 2];

const fonts = {
  body: '"Quicksand", Helvetica, Arial, sans-serif',
  heading: '"Lato", Helvetica, Arial, sans-serif',
  logo: '"Shrikhand", sans-serif',
};

const textStyles = {
  logoPrimary: {
    fontWeight: 900,
    fontSize: 24,
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
  },
  logoSecondary: {
    fontSize: 12,
    letterSpacing: 4,
    textTransform: 'uppercase',
    lineHeight: '14px',
    fontFamily: 'Montserrat, Helvetica, Arial, sans-serif',
  },
};

const Theme = {
  textStyles,
  borders,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  radii,
  space,
};

export default Theme;
