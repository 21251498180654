import styled from 'styled-components';
import {
  style,
  space,
  height,
  width,
  SpaceProps,
  HeightProps,
  WidthProps,
  ResponsiveValue,
} from 'styled-system';
import * as CSS from 'csstype';
import Img from 'gatsby-image';

const borderTopLeftRadius = style({
  prop: 'borderTopLeftRadius',
  cssProperty: 'borderTopLeftRadius',
});
const borderTopRightRadius = style({
  prop: 'borderTopRightRadius',
  cssProperty: 'borderTopRightRadius',
});
const borderBottomLeftRadius = style({
  prop: 'borderBottomLeftRadius',
  cssProperty: 'borderBottomLeftRadius',
});
const borderBottomRightRadius = style({
  prop: 'borderBottomRightRadius',
  cssProperty: 'borderBottomRightRadius',
});

interface RoundedImgProps extends HeightProps, SpaceProps, WidthProps {
  borderTopLeftRadius?: ResponsiveValue<CSS.BorderProperty<{}>>;
  borderTopRightRadius?: ResponsiveValue<CSS.BorderProperty<{}>>;
  borderBottomLeftRadius?: ResponsiveValue<CSS.BorderProperty<{}>>;
  borderBottomRightRadius?: ResponsiveValue<CSS.BorderProperty<{}>>;
  fixed?: {
    base64: string;
    height: number;
    src: string;
    srcSet: string;
    width: number;
  };
  fluid?: {
    aspectRatio: number;
    base64: string;
    sizes: string;
    src: string;
    srcSet: string;
  };
}

export const RoundedImg = styled(Img)<RoundedImgProps>`
  ${space}
  ${height}
  ${width}
  ${borderTopLeftRadius}
  ${borderTopRightRadius}
  ${borderBottomLeftRadius}
  ${borderBottomRightRadius}
`;
