import * as React from 'react';

export const ModalContext = React.createContext({
  component: null,
  props: {},
  showModal: (component: any, props: Object) => {},
  hideModal: () => {},
});

const defaultModalState = {
  component: null,
  props: {},
};

export const ModalProvider = ({
  children,
}) => {
  const [ modal, setModal ] = React.useState(defaultModalState);

  const showModal = (component = '', props = {}) => {
    setModal({
      component,
      props,
    });
  };

  const hideModal = () => {
    setModal(defaultModalState);
  };


  return (
    <ModalContext.Provider
      value={{
        component: modal.component,
        props: modal.props,
        showModal,
        hideModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}
