import * as React from 'react';
import { Flex } from '@westhechiang/flex';

export const BodyContainer = ({ children, ...props }) => (
  <Flex
    height="100%"
    alignItems="center"
    width={1}
    maxWidth={[1280]}
    {...props}
  >
    {children}
  </Flex>
);
