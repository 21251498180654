import * as React from 'react';
import { Flex } from '@westhechiang/flex';
import { Link } from '@westhechiang/link';
import { P } from '../P';
import { BodyContainer } from '../BodyContainer';
import { routes } from '../Layout/components/Navigation';
import { GatsbyLink } from '../core/gatsbyLink';
import { PurpleHeading } from '../Headings';

export const Footer = () => (
  <Flex
    width={1}
    bg="darkGray"
    pl={[3]}
    flexDirection="column"
    alignItems="center"
  >
    <BodyContainer>
      <Flex
        width={1}
        bg="darkGray"
        height={396}
        flexDirection="column"
        alignItems="flex-start"
        pt={82}
      >
        <PurpleHeading fontFamily="logo" mb={4} lineHeight="48px">
          Doja
        </PurpleHeading>
        {routes.map((route) => (
          <GatsbyLink
            key={route.label}
            to={route.path}
            color="lightGray"
            fontFamily="body"
            textTransform="uppercase"
            fontSize={2}
            fontWeight="bold"
            lineHeight="17px"
            letterSpacing="1px"
            mb={3}
          >
            {route.label}
          </GatsbyLink>
        ))}
        <Flex height="100%" alignItems="flex-end">
          <Link href="http://www.baileyrobb.com/" target="_blank">
            <P fontSize={3} color="lightGray">
              Photo Credit Bailey Robb
            </P>
          </Link>
        </Flex>
      </Flex>
    </BodyContainer>
  </Flex>
);
