import * as React from 'react';
import { Text } from '@chakra-ui/react';

export const P = ({ children, ...props }) => (
  <Text
    m={0}
    mb={5}
    color="darkGray"
    fontFamily="body"
    lineHeight={['36px', '26px', '36px']}
    fontWeight={500}
    fontSize={[14, 5, 5]}
    {...props}
  >
    {children}
  </Text>
);
