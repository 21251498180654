import * as React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { ModalContext } from './ModalProvider';
import { Flex } from '@westhechiang/flex';
import { Text } from '@westhechiang/text';
import { TextInput } from '../TextInput';
import { TextArea } from '../TextArea';
import { Button } from '../Button';

export const Modal = () => {
  const { hideModal, component } = React.useContext(ModalContext);

  const [values, setFormValue] = React.useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    business: '',
    message: '',
    response: {
      result: '',
      msg: '',
    },
  });

  React.useEffect(() => {
    if (values.response.result === 'success') {
      hideModal();
    }
  }, [values.response]);

  const handleInputChange = (e) => {
    e.persist();
    setFormValue((formValues) => ({
      ...formValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const result = await addToMailchimp(values.email, {
        FNAME: values.firstName,
        LNAME: values.lastName,
        PHONE: values.phone,
        BUSINESS: values.business,
        IMESSAGE: values.message,
        SOURCE: 'services',
      });

      setFormValue((formValues) => ({
        ...formValues,
        response: result,
      }));
    } catch (e) {
      console.log('could not send email to mailchimp:', e);
    }
  };

  if (!component) {
    return null;
  }

  // FIXME - We'll want to switch on the component here and spread the data
  // along if we add more modals

  return (
    <>
      <Flex
        position="fixed"
        top="0"
        left="0"
        height="100vh"
        width="100vw"
        bg="rgba(0,0,0, 0.5)"
        zIndex={10}
        onClick={hideModal}
      />
      <Flex
        width={[0.9, 'auto']}
        px={[3, 5]}
        pt={4}
        pb={3}
        minHeight={500}
        position="fixed"
        bg="pink"
        zIndex={10}
        top="35%"
        left="50%"
        transform="translate(-50%, -30%)"
        flexDirection="column"
      >
        {values.response.result === 'error' && values.response.msg && (
          <Flex width={1} justifyContent="center">
            <Text color="red" fontFamily="body">
              {values.response.msg}
            </Text>
          </Flex>
        )}
        <Flex width={1}>
          <TextInput
            border={0}
            fontFamily="body"
            fontWeight="bold"
            height={48}
            mb={1}
            onChange={handleInputChange}
            name="firstName"
            placeholder="First Name"
            placeholderColor="#BCC8D6"
            value={values.firstName}
            type="name"
            width={[1, 1, 1]}
          />

          <TextInput
            border={0}
            fontFamily="body"
            fontWeight="bold"
            height={48}
            mb={1}
            ml={1}
            name="lastName"
            onChange={handleInputChange}
            placeholder="Last Name"
            placeholderColor="#BCC8D6"
            value={values.lastName}
            type="name"
            width={[1, 1, 1]}
          />
        </Flex>

        <TextInput
          border={0}
          fontFamily="body"
          fontWeight="bold"
          height={48}
          mb={1}
          onChange={handleInputChange}
          name="email"
          placeholder="Email"
          placeholderColor="#BCC8D6"
          value={values.email}
          type="email"
          width={[1]}
        />

        <TextInput
          border={0}
          fontFamily="body"
          fontWeight="bold"
          height={48}
          mb={1}
          onChange={handleInputChange}
          name="phone"
          placeholder="Phone"
          placeholderColor="#BCC8D6"
          value={values.phone}
          type="tel"
          width={[1]}
        />

        <TextInput
          border={0}
          fontFamily="body"
          fontWeight="bold"
          height={48}
          mb={1}
          name="business"
          onChange={handleInputChange}
          placeholder="Business Name"
          placeholderColor="#BCC8D6"
          value={values.business}
          width={[1]}
        />

        <TextArea
          border={0}
          fontFamily="body"
          fontWeight="bold"
          height={200}
          mb={3}
          py={2}
          name="message"
          onChange={handleInputChange}
          placeholder="Message"
          placeholderColor="#BCC8D6"
          value={values.message}
          width={[1]}
        />
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={1}
        >
          <Button height="45px" onClick={handleSubmit}>
            Submit
          </Button>

          <Text
            fontFamily="body"
            onClick={hideModal}
            color="darkGray"
            hover
            mt={2}
          >
            Close
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
